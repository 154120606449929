<template>
    <div id="ser-representante" class="col-sm-12 row p-0">
        <div class="conteudo col-sm-12 row mt-0 m-0 p-0 d-flex justify-content-center">
            <div class="escrita col-lg-7 p-3 text-left d-flex align-content-end">
                <div class="col-sm-12 p-0">
                    <div class="agrupamento col-sm-12 ">
                        <div class="texto-destaque fonte-2">
                            Seja um representante <br> da <span class="texto-azul">Eagle Track.</span>
                        </div>
                        <div class="fonte-1 mt-2">
                            <span class="">Junte-se a nós!</span><br>
                            <!-- Como representante você terá vários benefícios. -->
                        </div>
                        <div class="fonte-1 mt-4">
                            Buscamos parceiros para atuar como vendedores independentes,
                            que queiram rentabilizar ainda mais sua carteira de clientes
                            apresentando os benefícios das nossas soluções!
                        </div>
                    </div>
                    <div class="col-sm-12 row p-0 mt-3">
                        <div class="col-sm-5 p-0">
                            <button class="btn btn-azul">Acessar portal do representante</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="formulario col-lg-4 row p-3">
                <div class="col-sm-12 p-0 row d-flex align-content-start flex-wrap">
                    <!-- <div class="col-sm-12 cabecalho p-0 text-left texto-branco fonte-form-1">
                        Cadastre-se e receba melhores informações
                    </div> -->
                    <div class="form-div row col-sm-12 p-0 d-flex justify-content-center align-items-center">
                        <div class="text-left texto-destaque col-sm-12 texto-branco mt-2">
                            Cadastre-se e receba melhores informações
                        </div>
                        <div class="col-sm-12 row p-0">
                            <div class="col-sm-12 pl-3 pr-3 text-left texto-branco">
                                Nome completo*
                            </div>
                            <div class="col-sm-12 pl-3 pr-3 mb-2">
                                <!-- :disabled="loadingButton" -->
                                <!-- v-model="franchisingName"  -->
                                <input
                                    :class="{'bordaError':alertaInput.inputNomeInvalido}"
                                    v-model="valueNome"
                                    id="franchisingName"
                                    type="text" 
                                    class="form-control" 
                                    name="namePersonFranchising"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 row p-0">
                            <div class="col-sm-12 pl-3 pr-3 text-left texto-branco">
                                CNPJ* 
                                <span class="small texto-destaque texto-vermelho"
                                    v-show="!cpfValido && valueCpf">Digite um CNPJ valido</span>
                            </div>
                            <div class="col-sm-12 pl-3 pr-3 mb-2">
                                <!-- @blur.native="testaCPF(valueCpf)" -->
                                <the-mask
                                    :class="{'bordaError':alertaInput.inputCPFInvalido}"
                                    id="input-cpf"
                                    :disabled="false"
                                    :masked="true"
                                    :mask="['##.###.###/####-##']" 
                                    name="fonePersonFranchising" 
                                    class="form-control"
                                    v-model="valueCpf" 
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 p-0">
                            <div class="col-sm-12 pl-3 pr-3 text-left texto-branco">
                                Email*
                                <span class="small texto-destaque texto-vermelho"
                                    v-show="$v.valueEmail.$invalid && valueEmail">Digite um EMAIL valido</span>
                            </div>
                            <div class="col-sm-12 pl-3 pr-3 mb-2">
                                <input
                                    :class="{'bordaError':alertaInput.inputEmailInvalido}"
                                    v-model="valueEmail"
                                    id="franchisingName"
                                    type="text" 
                                    class="form-control" 
                                    name="namePersonFranchising"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 p-0">
                            <div class="col-sm-12 pl-3 pr-3 text-left texto-branco">
                                Telefone*
                                <span class="small texto-destaque texto-vermelho"
                                    v-show="!(valueTelefone.length >= 14) && valueTelefone">
                                    Digite um TELEFONE valido
                                </span>
                            </div>
                            <div class="col-sm-12 pl-3 pr-3 mb-2">
                                <the-mask
                                    :class="{'bordaError':alertaInput.inputTelefoneInvalido}"
                                    v-model="valueTelefone"
                                    id="input-numero"
                                    :disabled="false"
                                    :masked="true" 
                                    :mask="['(##) #####-####', '(##) ####-####']" 
                                    name="fonePersonFranchising" 
                                    class="form-control" 
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 p-0">
                            <div class="col-sm-12 pl-3 pr-3 text-left texto-branco">
                                Estado*
                            </div>
                            <div class="col-sm-12 pl-3 pr-3 mb-2">
                                <selectAll
                                    ref="refEstado"
                                    :extraClass="{'bordaError':alertaInput.inputEstadoInvalido}"
                                    @changeSelect="changeEstado"
                                    :optionsArray="estados"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 p-0">
                            <div class="col-sm-12 pl-3 pr-3 text-left texto-branco">
                                Cidade*
                            </div>
                            <div class="col-sm-12 pl-3 pr-3 mb-2">
                                <selectAll
                                    ref="refCidade"
                                    :loading='loadingCidade'
                                    :extraClass="{'bordaError':alertaInput.inputCidadeInvalido}"
                                    @changeSelect="changeCidade"
                                    :optionsArray="cidades"/>
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 row d-flex justify-content-center">
                            <div class="col-sm-7 p-0 text-left d-flex align-items-center texto-branco">
                                <b-form-checkbox size="sm" class="d-flex justify-content-center">
                                    Já é representante de outro serviço?
                                </b-form-checkbox>
                            </div>
                            <div class="col-sm-4 p-0 text-left texto-branco">
                                <b-button @click="validaFormulario" 
                                    class="col-sm-12 btn-env" 
                                    :disabled="disabledButton">
                                    <b-spinner small type="grow" v-show="loadingButton"/>
                                    Enviar
                                </b-button>
                            </div>
                        </div>
                        <div class="col-sm-12 row mt-2 mb-2">
                            <div class="texto-branco small text-justify text-lgpd nopadding">
                                <span class="text-muted small col-sm-12 text-justify text-lgpd nopadding">
                                    Ao clicar em enviar você concorda com o nosso 
                                        <span class="texto-azul" 
                                            @click="$router.push({name:'PoliticaDePrivacidade'})">
                                            Termo de Uso 
                                        </span> e 
                                        <span class="texto-azul" 
                                            @click="$router.push({name:'TermosDeUso'})">
                                            Política de Privacidade
                                        </span>. 
                                    Este consentimento serve para atendimento aos requisitos da Lei nº 13.709/18
                                    (Lei Geral de Proteção de Dados).
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 row p-0 d-flex justify-content-center" id="ser-aguia">
            <div class="col-sm-12 row p-0 d-flex justify-content-center">
                <div class="col-sm-8 p-0 titulo-eagle">
                    <!-- <div class="col-sm-12 subtitulo text-center">
                        <span class="texto-destaque">EAGLE </span> TRACK
                    </div> -->
                    <div class="mt-5">
                        <h2 class="texto-destaque">
                            VEM SER <span class="texto-azul">ÁGUIA</span>, 
                            VAMOS JUNTOS ALÇAR VOOS MAIS ALTOS E DISTANTES
                        </h2>
                    </div>
                </div>
                <div class="d-flex justify-content-center row col-sm-12 p-0">
                    <div class="col-md-9 col-lg-8">
                        Na Eagle Track abrimos um mundo de possibilidades para o seu desenvolvimento e carreira, 
                        <span class="texto-destaque">
                            SEJA UM REPRESENTANTE.
                        </span>
                    </div>
                </div>
                <!-- <div class="col-sm-12 p-0 faixa">
                <span class="small texto-destaque texto-azul">Vamos juntos alçar voos mais altos e distantes!</span>
                </div> -->
                <div class="col-sm-12 p-0 row d-flex justify-content-center ">
                    <div class="col-sm-8 row d-flex justify-content-center">
                        <div class="col-sm-12 mt-2 text-justify">
                            <span>
                                Somos movidos pela <span class="texto-azul">
                                satisfação de nossos clientes</span> 
                                e pelos resultados de pessoas talentosas, 
                                apaixonadas pelo que fazem e que querem ir cada vez mais longe.
                            </span>
                        </div>
                        <div class="col-sm-12 mt-2 text-justify">
                            <span>
                                Desenvolvemos softwares que buscam automatizar processos e 
                                levantar informações fundamentais
                                para a gestão de frotas nos clientes, 
                                    <span class="texto-azul">
                                        reduzindo custos e aumentando a produtividade e os seus lucros
                                    </span>.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 row mt-3 p-0 d-flex justify-content-center">
                <div class="col-md-12 col-lg-8 row borda-titulo pt-3 pb-3 d-flex justify-content-center">
                    <div class="col-sm-8 d-flex justify-content-center">
                        <h4 class="m-0 p-0 texto-destaque">
                            OPORTUNIDADES
                        </h4>
                    </div>
                </div>
                <div class="col-sm-6 p-3 row text-left d-flex justify-content-end">
                    <div class="col-sm-8 row card-oportunidades p-3">
                        <div class="col-sm-12 p-0">
                            <span class="texto-destaque">
                                <h5>
                                    REQUISITOS
                                </h5>
                            </span>
                        </div>
                        <div class="col-sm-12 p-0 row mt-2">
                            <div class="p-0">
                                <div style="width:100%" class="p-0 mr-2">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="currentColor" 
                                            d="M10 16V15H3L3 19C3 20.11 3.89 21 5 
                                            21H19C20.11 21 21 20.11 21 
                                            19V15H14V16H10M20 7H16V5L14 
                                            3H10L8 5V7H4C2.9 7 2 7.9 2 
                                            9V12C2 13.11 2.89 14 4 
                                            14H10V12H14V14H20C21.1 14 
                                            22 13.1 22 12V9C22 7.9 21.1 
                                            7 20 7M14 7H10V5H14V7Z" 
                                            />
                                    </svg>
                                </div>
                            </div>
                            <div style="width:90%" class="p-0">
                                <div class="p-0">
                                    <span>
                                        Mínimo de 3 anos de experiência em vendas como representante comercial.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 row mt-2">
                            <div class="p-0">
                                <div style="width:100%" class="p-0 mr-2">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="currentColor" 
                                            d="M12,8.5H7L4,11H3C1.89,11 1,11.89 1,
                                            13V16H3.17C3.6,17.2 4.73,18 6,18C7.27,
                                            18 8.4,17.2 8.82,16H15.17C15.6,17.2 
                                            16.73,18 18,18C19.27,18 20.4,17.2 
                                            20.82,16H23V15C23,13.89 21.97,13.53 
                                            21,13L12,8.5M5.25,12L7.5,10H11.5L15.5,
                                            12H5.25M6,13.5A1.5,1.5 0 0,1 7.5,15A1.5,
                                            1.5 0 0,1 6,16.5A1.5,1.5 0 0,1 4.5,15A1.5,
                                            1.5 0 0,1 6,13.5M18,13.5A1.5,1.5 0 0,1 19.5,
                                            15A1.5,1.5 0 0,1 18,16.5A1.5,1.5 0 0,1 16.5,
                                            15A1.5,1.5 0 0,1 18,13.5Z" 
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div style="width:90%" class="p-0">
                                <div class="p-0">
                                    <span>
                                        Possuir veículo próprio.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 row mt-2">
                            <div class="p-0">
                                <div style="width:100%" class="p-0 mr-2">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="currentColor" 
                                            d="M22,3H2C0.91,3.04 0.04,
                                            3.91 0,5V19C0.04,20.09 0.91,20.96 2,
                                            21H22C23.09,20.96 23.96,20.09 24,
                                            19V5C23.96,3.91 23.09,3.04 22,3M22,
                                            19H2V5H22V19M14,17V15.75C14,14.09 
                                            10.66,13.25 9,13.25C7.34,13.25 4,
                                            14.09 4,15.75V17H14M9,7A2.5,2.5 0 
                                            0,0 6.5,9.5A2.5,2.5 0 0,0 9,
                                            12A2.5,2.5 0 0,0 11.5,9.5A2.5,
                                            2.5 0 0,0 9,7M14,7V8H20V7H14M14,
                                            9V10H20V9H14M14,11V12H18V11H14" 
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div style="width:90%" class="p-0">
                                <div class="p-0">
                                    <span>
                                        Registro no CORE.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 text-center mt-2">
                            <span class="small texto-destaque">
                                PROFISSIONAL QUE NÃO TENHA MEDO DE DESAFIOS!
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 p-3 row text-left d-flex justify-content-start">
                    <div class="col-sm-8 row card-oportunidades p-3">
                        <div class="col-sm-12 p-0">
                            <span class="texto-destaque">
                                <h5>
                                    VANTAGENS
                                </h5>
                            </span>
                        </div>
                        <div class="col-sm-12 p-0 row mt-2">
                            <div class="p-0">
                                <div style="width:100%" class="p-0 mr-2">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="currentColor" 
                                            d="M6,16.5L3,19.44V11H6M11,
                                            14.66L9.43,13.32L8,
                                            14.64V7H11M16,13L13,16V3H16M18.81,
                                            12.81L17,11H22V16L20.21,14.21L13,
                                            21.36L9.53,18.34L5.75,22H3L9.47,
                                            15.66L13,18.64" 
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div style="width:90%" class="p-0">
                                <div class="p-0">
                                    <span>
                                        Mercado crescente
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 row mt-2">
                            <div class="p-0">
                                <div style="width:100%" class="p-0 mr-2">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="currentColor" 
                                            d="M14,6L10.25,11L13.1,14.8L11.5,
                                            16C9.81,13.75 7,10 7,10L1,18H23L14,6Z" 
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div style="width:90%" class="p-0">
                                <div class="p-0">
                                    <span>
                                        Empresa constituída com mais de 5 anos de mercado.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 row mt-2">
                            <div class="p-0">
                                <div style="width:100%" class="p-0 mr-2">
                                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="currentColor" 
                                            d="M17.71 6.15C17.46 5.38 16.79 5.21 
                                            16.45 4.77C16.14 4.31 16.18 3.62 15.53 
                                            3.15S14.23 2.92 13.7 2.77 12.81 2 12 2 
                                            10.82 2.58 10.3 2.77 9.13 2.67 8.47 3.15 
                                            7.86 4.31 7.55 4.77C7.21 5.21 6.55 5.38 
                                            6.29 6.15S6.5 7.45 6.5 8 6 9.08 6.29 9.85 
                                            7.21 10.79 7.55 11.23C7.86 11.69 7.82 12.38 
                                            8.47 12.85S9.77 13.08 10.3 13.23 11.19 14 
                                            12 14 13.18 13.42 13.7 13.23 14.87 13.33 
                                            15.53 12.85 16.14 11.69 16.45 11.23C16.79 
                                            10.79 17.45 10.62 17.71 9.85S17.5 8.55 
                                            17.5 8 18 6.92 17.71 6.15M12 12A4 4 0 1 1 
                                            16 8A4 4 0 0 1 12 12M14 8A2 2 0 1 1 12 6A2 
                                            2 0 0 1 14 8M13.71 15.56L13.08 19.16L12.35 
                                            23.29L9.74 20.8L6.44 22.25L7.77 14.75A4 4 
                                            0 0 0 9.66 15.17A4.15 4.15 0 0 0 11 15.85A3.32 
                                            3.32 0 0 0 12 16A3.5 3.5 0 0 0 13.71 
                                            15.56M17.92 18.78L15.34 17.86L15.85 
                                            14.92A3.2 3.2 0 0 0 16.7 14.47L16.82 14.37Z" 
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div style="width:90%" class="p-0">
                                <div class="p-0">
                                    <span>
                                        Reconhecimento!
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 text-center mt-2">
                            <span class="small texto-destaque">
                                <!-- Você é empreendedor! -->
                                SEJA UM EMPREENDEDOR!
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-sm-12 row p-0 d-flex justify-content-center"> -->
                <!-- <div class="col-sm-12 row p-0 d-flex justify-content-center"> -->
                    <!-- <div class="col-md-12 col-lg-8 row borda-titulo pt-3 pb-3 d-flex justify-content-center">
                        <div class="col-sm-12 d-flex justify-content-center">
                            <h4 class="m-0 p-0 texto-destaque">
                                VOCÊ VAI MELHORAR A VIDA DAS PESSOAS E DAS EMPRESAS
                            </h4>
                        </div>
                    </div> -->
                    <!-- <div class="col-sm-12 row p-0 d-flex justify-content-center mt-2"> -->
                        <!-- <div class="col-md-12 col-lg-8 p-0 row p-0 d-flex justify-content-center"> -->
                            <!-- <div class="col-md-7 p-0 row d-flex justify-content-center">
                                <div class="col-md-12 p-0 d-flex justify-content-center">
                                    <div style="width:20%" class="p-0">
                                        <svg style="
                                                max-width:150px; 
                                                min-width:100px; 
                                                width:70%;
                                                height:70%" 
                                            viewBox="0 0 24 24">
                                            <path fill="currentColor" 
                                                d="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 
                                                14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 
                                                0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 
                                                0 0,1 19,8H9M1,10H3V20H19V22H1V10Z" 
                                            />
                                        </svg>
                                    </div>
                                    <div style="max-width:80%; min-width:70%" class="text-left">
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span class="texto-destaque texto-azul">Reduz custos</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span>
                                                    Otimizando o tempo, evitando custos extras e com as melhores 
                                                    praticas o resultado vem mais rápido.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6 p-0 row d-flex justify-content-center">
                                <div class="col-md-12 p-0 d-flex justify-content-center">
                                    <div style="width:20%" class="p-0">
                                        <svg style="
                                                max-width:150px; 
                                                min-width:100px; 
                                                width:70%;
                                                height:70%" 
                                            viewBox="0 0 24 24">
                                            <path fill="currentColor" 
                                                d="M3.55,18.54L4.96,19.95L6.76,18.16L5.34,16.74M11,
                                                22.45C11.32,22.45 13,22.45 13,22.45V19.5H11M12,
                                                5.5A6,6 0 0,0 6,11.5A6,6 0 0,0 12,17.5A6,6 0 0,
                                                0 18,11.5C18,8.18 15.31,5.5 12,5.5M20,12.5H23V10.5H20M17.24,
                                                18.16L19.04,19.95L20.45,18.54L18.66,16.74M20.45,4.46L19.04,
                                                3.05L17.24,4.84L18.66,6.26M13,0.55H11V3.5H13M4,10.5H1V12.5H4M6.76,
                                                4.84L4.96,3.05L3.55,4.46L5.34,6.26L6.76,4.84Z" 
                                            />
                                        </svg>
                                    </div>
                                    <div style="max-width:80%; min-width:70%" class="text-left">
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span class="texto-destaque texto-azul">Equilíbrio</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span>
                                                    Ele enfatiza a distinção entre utilizar e explorar recursos.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6 p-0 row d-flex justify-content-center">
                                <div class="col-md-12 p-0 d-flex justify-content-center">
                                    <div style="width:20%" class="p-0">
                                        <svg style="
                                                max-width:150px; 
                                                min-width:100px; 
                                                width:70%;
                                                height:70%" 
                                            viewBox="0 0 24 24">
                                            <path fill="currentColor" 
                                                d="M17.5 14.33C18.29 14.33 19.13 14.41 
                                                20 14.57V16.07C19.38 15.91 18.54 15.83 
                                                17.5 15.83C15.6 15.83 14.11 16.16 13 
                                                16.82V15.13C14.17 14.6 15.67 14.33 
                                                17.5 14.33M13 12.46C14.29 11.93 15.79 
                                                11.67 17.5 11.67C18.29 11.67 19.13 11.74 
                                                20 11.9V13.4C19.38 13.24 18.54 13.16 17.5 
                                                13.16C15.6 13.16 14.11 13.5 13 14.15M17.5 
                                                10.5C15.6 10.5 14.11 10.82 13 11.5V9.84C14.23 
                                                9.28 15.73 9 17.5 9C18.29 9 19.13 9.08 20 
                                                9.23V10.78C19.26 10.59 18.41 10.5 17.5 10.5M21 
                                                18.5V7C19.96 6.67 18.79 6.5 17.5 6.5C15.45 
                                                6.5 13.62 7 12 8V19.5C13.62 18.5 15.45 18 
                                                17.5 18C18.69 18 19.86 18.16 21 18.5M17.5 
                                                4.5C19.85 4.5 21.69 5 23 6V20.56C23 20.68 
                                                22.95 20.8 22.84 20.91C22.73 21 22.61 21.08 
                                                22.5 21.08C22.39 21.08 22.31 21.06 22.25 21.03C20.97 
                                                20.34 19.38 20 17.5 20C15.45 20 13.62 20.5 
                                                12 21.5C10.66 20.5 8.83 20 6.5 20C4.84 20 3.25 
                                                20.36 1.75 21.07C1.72 21.08 1.68 21.08 1.63 
                                                21.1C1.59 21.11 1.55 21.12 1.5 21.12C1.39 
                                                21.12 1.27 21.08 1.16 21C1.05 20.89 1 20.78 
                                                1 20.65V6C2.34 5 4.18 4.5 6.5 4.5C8.83 4.5 
                                                10.66 5 12 6C13.34 5 15.17 4.5 17.5 4.5Z" 
                                            />
                                        </svg>
                                    </div>
                                    <div style="max-width:80%; min-width:70%" class="text-left">
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span class="texto-destaque texto-azul">Equilibrio</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span>
                                                    Planeje em torno de bens culturais existentes, 
                                                    praticas tradicionais e estilo.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-7 p-0 row d-flex justify-content-center">
                                <div class="col-md-12 p-0 d-flex justify-content-center">
                                    <div style="width:20%" class="p-0">
                                        <svg style="
                                                max-width:150px; 
                                                min-width:100px; 
                                                width:70%;
                                                height:70%" 
                                            viewBox="0 0 24 24">
                                            <path fill="currentColor" 
                                                d="M17.5 14.33C18.29 14.33 19.13 14.41 
                                                20 14.57V16.07C19.38 15.91 18.54 15.83 
                                                17.5 15.83C15.6 15.83 14.11 16.16 13 
                                                16.82V15.13C14.17 14.6 15.67 14.33 
                                                17.5 14.33M13 12.46C14.29 11.93 15.79 
                                                11.67 17.5 11.67C18.29 11.67 19.13 11.74 
                                                20 11.9V13.4C19.38 13.24 18.54 13.16 17.5 
                                                13.16C15.6 13.16 14.11 13.5 13 14.15M17.5 
                                                10.5C15.6 10.5 14.11 10.82 13 11.5V9.84C14.23 
                                                9.28 15.73 9 17.5 9C18.29 9 19.13 9.08 20 
                                                9.23V10.78C19.26 10.59 18.41 10.5 17.5 10.5M21 
                                                18.5V7C19.96 6.67 18.79 6.5 17.5 6.5C15.45 
                                                6.5 13.62 7 12 8V19.5C13.62 18.5 15.45 18 
                                                17.5 18C18.69 18 19.86 18.16 21 18.5M17.5 
                                                4.5C19.85 4.5 21.69 5 23 6V20.56C23 20.68 
                                                22.95 20.8 22.84 20.91C22.73 21 22.61 21.08 
                                                22.5 21.08C22.39 21.08 22.31 21.06 22.25 21.03C20.97 
                                                20.34 19.38 20 17.5 20C15.45 20 13.62 20.5 
                                                12 21.5C10.66 20.5 8.83 20 6.5 20C4.84 20 3.25 
                                                20.36 1.75 21.07C1.72 21.08 1.68 21.08 1.63 
                                                21.1C1.59 21.11 1.55 21.12 1.5 21.12C1.39 
                                                21.12 1.27 21.08 1.16 21C1.05 20.89 1 20.78 
                                                1 20.65V6C2.34 5 4.18 4.5 6.5 4.5C8.83 4.5 
                                                10.66 5 12 6C13.34 5 15.17 4.5 17.5 4.5Z" 
                                            />
                                        </svg>
                                    </div>
                                    <div style="max-width:80%; min-width:70%" class="text-left">
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span class="texto-destaque texto-azul">Equilíbrio</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span>
                                                    Planeje em torno de bens culturais existentes, 
                                                    praticas tradicionais e estilo.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-6 p-0 row d-flex justify-content-center">
                                <div class="col-md-12 p-0 d-flex justify-content-center">
                                    <div style="width:20%" class="p-0">
                                        <svg style="
                                                max-width:150px; 
                                                min-width:100px; 
                                                width:70%;
                                                height:70%" 
                                            viewBox="0 0 24 24">
                                            <path fill="currentColor" 
                                                d="M12,21L15.6,16.2C14.6,15.45 13.35,15 
                                                12,15C10.65,15 9.4,15.45 8.4,16.2L12,
                                                21M12,3C7.95,3 4.21,4.34 1.2,6.6L3,9C5.5,
                                                7.12 8.62,6 12,6C15.38,6 18.5,7.12 21,9L22.8,
                                                6.6C19.79,4.34 16.05,3 12,3M12,9C9.3,9 6.81,
                                                9.89 4.8,11.4L6.6,13.8C8.1,12.67 9.97,12 12,
                                                12C14.03,12 15.9,12.67 17.4,13.8L19.2,11.4C17.19,
                                                9.89 14.7,9 12,9Z" 
                                            />
                                        </svg>
                                    </div>
                                    <div style="max-width:80%; min-width:70%" class="text-left">
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span class="texto-destaque texto-azul">Tecnologia</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span>
                                                    Ele enfatiza o empregado de sistemas de construção 
                                                    de matérias que são consistentes com os contextos locais
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="col-md-7 p-0 row d-flex justify-content-center">
                                <div class="col-md-12 p-0 d-flex justify-content-center">
                                    <div style="width:20%" class="p-0">
                                        <svg style="
                                                max-width:150px; 
                                                min-width:100px; 
                                                width:70%;
                                                height:70%" 
                                            viewBox="0 0 24 24">
                                            <path fill="currentColor" 
                                                d="M12,21L15.6,16.2C14.6,15.45 13.35,15 
                                                12,15C10.65,15 9.4,15.45 8.4,16.2L12,
                                                21M12,3C7.95,3 4.21,4.34 1.2,6.6L3,9C5.5,
                                                7.12 8.62,6 12,6C15.38,6 18.5,7.12 21,9L22.8,
                                                6.6C19.79,4.34 16.05,3 12,3M12,9C9.3,9 6.81,
                                                9.89 4.8,11.4L6.6,13.8C8.1,12.67 9.97,12 12,
                                                12C14.03,12 15.9,12.67 17.4,13.8L19.2,11.4C17.19,
                                                9.89 14.7,9 12,9Z" 
                                            />
                                        </svg>
                                    </div>
                                    <div style="max-width:80%; min-width:70%" class="text-left">
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span class="texto-destaque texto-azul">Tecnologia</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 p-0">
                                            <div class="col-sm-12">
                                                <span>
                                                    Ele enfatiza o empregado de sistemas de construção de 
                                                    matérias que são consistentes com os contextos locais.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                        <!-- </div> -->
                    <!-- </div> -->
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
</template>
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-100341043-1"></script>
<script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());  gtag('config', 'UA-100341043-1');
</script>
<script lang="js">
import Vue from 'vue'
import Swal from 'sweetalert2'
import { required, email } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import SelectAll from '@/components/inputs/SelectAll.vue'
import { HttpRequest } from '@/Services/Request/HttpRequest.Service'
export default Vue.extend({
    // inheritAttrs :false,
    components:{
        TheMask,
        SelectAll,
    },
    validations:{
        valueNome: { required },
        valueCpf: { required },
        valueEmail: { email, required },
        valueTelefone: { required },
        valueEstado: { required },
        valueCidade: { required },
    },
    data() {
        return {
            estados:[],
            cidades:[],
            
            valueNome:'',
            valueCpf:'',
            valueEmail:'',
            valueTelefone:'',
            valueEstado:'',
            valueCidade:'',
            valueRepresentante:false,
            
            cpfValido:false,
            emailValido:false,
            loadingCidade:false,

            loadingButton:false,
            disabledButton:false,

            alertaInput:{
                inputNomeInvalido:false,
                inputCPFInvalido:false,
                inputEmailInvalido:false,
                inputTelefoneInvalido:false,
                inputEstadoInvalido:false,
                inputCidadeInvalido:false,
            },
        }
    },
    watch:{
        valueCpf:function(val){
            this.cpfValido = this.validarCNPJ(val) 
        }
    },
    methods: {
        validaFormulario(){
            if(this.loadingButton || this.disabledButton) return
            this.alertaInput.inputNomeInvalido = !this.valueNome
            this.alertaInput.inputCPFInvalido = !this.valueCpf || !this.cpfValido
            this.alertaInput.inputEmailInvalido = !this.valueEmail || this.$v.valueEmail.$invalid 
            this.alertaInput.inputTelefoneInvalido = !(this.valueTelefone.length >= 14) || !this.valueTelefone
            this.alertaInput.inputEstadoInvalido = !this.valueEstado
            this.alertaInput.inputCidadeInvalido = !this.valueCidade
            setTimeout(function(){
                this.alertaInput.inputNomeInvalido = false
                this.alertaInput.inputCPFInvalido = false
                this.alertaInput.inputEmailInvalido = false
                this.alertaInput.inputTelefoneInvalido = false
                this.alertaInput.inputEstadoInvalido = false
                this.alertaInput.inputCidadeInvalido = false   
            }.bind(this), 3000)
            if(Object.values(this.alertaInput).find(v => v === true)) return
            this.fazRequestFormulario()
        },
        
        async fazRequestFormulario(){
            var obj = {
                nomeCompleto:this.valueNome,
                cpf:this.valueCpf,
                email:this.valueEmail,
                telefone:this.valueTelefone,
                estado:this.valueEstado,
                cidade:this.valueCidade,
                representante:this.valueRepresentante
            }
            var html = `
                <apan> Cadastro realizado com sucesso! </span> <br> 
                <apan class="text-muted"> Retornaremos seu contato o mais breve possível. </span>
            `
            this.loadingButton = true
            this.disabledButton = true
            new HttpRequest()
                .Post('apresentacao/eagle/representante', {obj:obj})
                .then((res) => {
                    Swal.fire({
                        icon:  'success',
                        title: 'Obrigado!',
                        html: html,
                        showConfirmButton: false,
                        timer: 4500
                    })
                    this.loadingButton = false
                    this.disabledButton = false
                    this.limpaVariavel()
                }).catch((err) => {
                    Swal.fire({
                        icon:  'error',
                        title: 'Oops... <br> Algo inesperado aconteceu',
                        html:  `<apan> 
                            Tente novamente mais tarde
                            </span><br> 
                            <span class="small"> 
                                Verifique sua internet ou seu arquivo 
                            </span>`,
                        footer: '',
                    })
                    this.loadingButton = false
                    this.disabledButton = false
                })
        },
        
        limpaVariavel(){
            this.valueNome = ''
            this.valueCpf = ''
            this.valueEmail = ''
            this.valueTelefone = ''
            this.valueEstado = ''
            this.valueCidade = ''
            this.valueRepresentante = false
            this.$refs.refEstado.clearAll()
            this.$refs.refCidade.clearAll()
            this.cidades = []
        },

        changeEstado(value, x, estado){
            this.valueEstado = estado
            this.cidades = []
            this.$refs.refCidade.clearAll()
            this.loadingCidade = true
            this.axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+value[0]+'/municipios')
                .then((response) => {
                    for(const x of response.data){
                        this.cidades.push({value:x.id, description:x.nome})
                    }
                    this.loadingCidade = false
                }).catch((err) => {
                    this.loadingCidade = false
                })
        },

        changeCidade(value, x, cidade){
            this.valueCidade = cidade 
        },

        validarCNPJ(cnpj) {
            cnpj = cnpj.replace(/[^\d]+/g,'');
            if(cnpj == '') return false
            if (cnpj.length != 14) return false
            // Elimina CNPJs invalidos conhecidos
            // todo o resto eu peguei na net, menos esse if
            if(cnpj === cnpj.split("").reverse().join("")){
                return false
            }
            // Valida DVs
            let tamanho = cnpj.length - 2
            let numeros = cnpj.substring(0,tamanho)
            let digitos = cnpj.substring(tamanho)
            let soma = 0
            let pos = tamanho - 7
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--
                if(pos < 2) pos = 9
            }
            let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
            if (resultado != digitos.charAt(0)) return false
            tamanho = tamanho + 1
            numeros = cnpj.substring(0,tamanho)
            soma = 0
            pos = tamanho - 7
            for (let i = tamanho; i >= 1; i--) {
                soma += numeros.charAt(tamanho - i) * pos--
                if(pos < 2) pos = 9
            }
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
            if (resultado != digitos.charAt(1)) return false
            return true
        },




        // n usa mais cpf, só cnpj
        testaCPF(value){
            var strCPF = value.replace(/[^0-9]/g, '')
            var soma = 0
            var resto
            if(strCPF.length !== 11){
                return false
            } 
            if(strCPF === strCPF.split("").reverse().join("")){
                return false
            }
            for(let i = 1; i<=9; i++){
                soma = soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
            } 
            resto = (soma * 10) % 11
            if((resto == 10) || (resto == 11)){
                resto = 0
            } 
            if(resto != parseInt(strCPF.substring(9, 10))){
                return false
            }
            soma = 0
            for(let i = 1; i <= 10; i++){
                soma = soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)
            }
            resto = (soma * 10) % 11
            if((resto == 10) || (resto == 11)){
                resto = 0
            }
            if(resto != parseInt(strCPF.substring(10, 11))){
                return false
            } 
            return true
        }
    },
    mounted(){
        this.loadingEstado = true
        this.axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
            .then((response) => {
                for (const x of response.data){
                    this.estados.push({value:x.id, description:`${x.nome} (${x.sigla})`})
                }
                this.loadingEstado = false
            }).catch((err)=>{
                this.loadingEstado = false
            })
    },
})
</script>

<style lang="scss">
    #ser-representante{
        //  a fer n gostou
        // background-color: #1e1e1e;
        background-color: #FFF;
        padding-top: 90px !important;
        padding-bottom: 50px !important;
        // background: rgb(0,0,0);
        // background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(32,45,98, .7) 30%, rgba(0,212,255,.6) 100%);
        // z-index: -1;
        #bakground-representante{
            // max-height: 95vh;
            height: 100vh;
            position: absolute;
        }
        .conteudo{
            // z-index:-1;
            position: relative;
            top: -1vh;
            left: 0%;
            right: 0%;
            bottom: 0%;
            background-image: url("../assets/images/serRepresentanteEagle.jpeg") !important;
            background-repeat: no-repeat !important;
            background-size: cover;
            background-position: center;
            // max-height: 95vh;
            .escrita{
                // border: 1px solid red;
                .agrupamento{
                    border-radius: 0 !important;
                    margin-top: 40px;
                    color: white;
                    background-color: rgba(52, 58, 64, 0.6);
                    box-shadow: 0 1 .5em #343a40, 0 0 .5em rgba(52, 58, 64, 0.6);
                    // background-attachme;
                    // border: 1px solid red;
                    padding-top: 60px;
                    padding-left: 60px;
                    padding-bottom: 60px;
                    .fonte-1{
                        font-size: clamp(1em, calc(1em + 1vw), 1.5em);
                    }
                    .fonte-2{
                        font-size: clamp(2em, calc(2em + 1vw), 2.5em);
                    }
                }
            }
            .formulario{
                width: 100% !important;
                height: 100% !important;
                // z-index: 1;
                // position: absolute; 
                // border: 1px solid red !important;
                background-color: hsla(0, 0%, 0%, 0) !important;
                .cabecalho{
                    // border: 1px solid red;
                    // max-height: 40px;
                }
                .form-div{
                    color: black;
                    width: 100% !important;
                    height: 100% !important;
                    max-height: 600px !important;
                    background-color: #343a40 !important;
                }
                .fonte-form-1{
                    font-size: clamp(1em, calc(1em + 1vw), 1.5em);
                    // font-size: clamp(1em, calc(1em + 1vw), 1.2em);
                }
                .btn-env{
                    border-radius: 0px !important;
                    border: 1px solid #2196f3 !important;
                    color: #fff !important;
                    background-color:#2196f3 !important;
                    border-radius: 0px !important;
                    &:hover{
                        border: 1px solid #fff !important;
                        color: #FFF !important;
                        background-color: #2196f3 !important;
                    }
                }
            }
        }
        #ser-aguia{
            background-color: #FFF;
            color: black;
            div{
                svg{
                    color: #42a8d6;
                }
            }
            .titulo-eagle{
                .subtitulo{
                    border-bottom: 1px solid #FFF;
                }
            }
            // .faixa{
            //     // background-color: #282828;
            // }
            .card-oportunidades{
                border: 3px solid #545454;
            }
            .borda-titulo{
                text-align: left;
                border-top: #545454 solid;
                border-bottom: #545454 solid;
            }
            .borda{
                border: 1px solid red;
            }
        }
    }
</style>